<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.999512 4.49938C0.999512 1.8739 1.02762 0.999023 4.49987 0.999023C7.97211 0.999023 8.00022 1.8739 8.00022 4.49938C8.00022 7.12486 8.0113 7.99973 4.49987 7.99973C0.988438 7.99973 0.999512 7.12486 0.999512 4.49938Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9995 4.49938C11.9995 1.8739 12.0276 0.999023 15.4999 0.999023C18.9721 0.999023 19.0002 1.8739 19.0002 4.49938C19.0002 7.12486 19.0113 7.99973 15.4999 7.99973C11.9884 7.99973 11.9995 7.12486 11.9995 4.49938Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.999512 15.5006C0.999512 12.8751 1.02762 12.0002 4.49987 12.0002C7.97211 12.0002 8.00022 12.8751 8.00022 15.5006C8.00022 18.1261 8.0113 19.001 4.49987 19.001C0.988438 19.001 0.999512 18.1261 0.999512 15.5006Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9995 15.5004C11.9995 12.8749 12.0276 12 15.4999 12C18.9721 12 19.0002 12.8749 19.0002 15.5004C19.0002 18.1258 19.0113 19.0007 15.4999 19.0007C11.9884 19.0007 11.9995 18.1258 11.9995 15.5004Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconGrid',
}
</script>
