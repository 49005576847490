<template>
  <div class="vPreloader">
    <img
      src="~@/assets/images/preloader.gif"
      alt="Загрузка..."
      class="vPreloader__img"
    >
    <div class="vPreloader__text">
      Выгрузка меню {{ counter }} %
    </div>
  </div>
</template>

<script>
export default {
  name: 'VPreloaderTaimer',
  props: {
    counter: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  emits: ['invocationPreloader', 'counterIncrement'],
  data() {
    return {
      polling: null,
    }
  },
  mounted() {
    this.timer()
  },
  beforeUnmount() {
    clearInterval(this.polling)
  },
  methods: {
    timer() {
      // this.polling = setInterval(() => {
      //   if (this.counter !== 87) {
      //     this.$emit('counterIncrement', this.counter + 1)
      //   }
      //   if (this.counter >= 100) {
      //     clearInterval(this.polling)
      //     this.invocationPreloader()
      //   }
      // }, 20)
      console.log('this.counter', this.counter)
    },
    invocationPreloader() {
      this.$emit('invocationPreloader')
    },
  },
}
</script>
<style lang="scss" scoped>
.vPreloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f8fa;
  flex-direction: column;
  &__img {
    width: 110px;
    height: 110px;
    margin-bottom: 10px;
    @media (max-width: 769px) {
      width: 96px;
      height: 96px;
    }
  }
  &__text {
    color: #010d35;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Commissioner';
  }
}
</style>
