<template>
  <div class="vAdminMenuCategoryItem">
    <div
      class="vAdminMenuCategoryItem__info"
      :class="{ active: active }"
      @click="selectCatgory"
    >
      <div class="vAdminMenuCategoryItem__info-top">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.66602 8H12.3327"
            stroke="#80869A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.66602 10.668H12.3327"
            stroke="#80869A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.66602 5.33398H12.3327"
            stroke="#80869A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Позиции ({{ data.products.length }} шт)
      </div>
      <div class="vAdminMenuCategoryItem__info-bottom">
        {{ data.order && data.order + '.' }}
        {{ data.our_name && data.our_name !== '-' ? data.our_name : data.name }}
      </div>
    </div>
    <div
      class="vAdminMenuCategoryItem__btn"
      @click="selectCatgoryEdit"
    >
      <VAdminMenuBtn type="edit" />
    </div>
    <div
      class="vAdminMenuCategoryItem__btn"
      :class="{ active: !data.hidden }"
      @click="showHideCategory"
    >
      <VAdminMenuBtn
        :type="data.hidden ? 'eyeClose' : 'eye'"
        :active="!data.hidden && true"
      />
    </div>
  </div>
</template>

<script>
import VAdminMenuBtn from '@/components/adminMenu/v-admin-menu-btn'
export default {
  name: 'VAdminMenuCategoryItem',
  components: { VAdminMenuBtn },

  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({
        name: 'ЛИМОНАДЫ',
        hidden: 0,
        products: [],
      }),
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['selectCatgoryEdit', 'selectCatgory', 'showHideCategory'],
  methods: {
    selectCatgoryEdit() {
      this.$emit('selectCatgoryEdit', this.data)
    },
    selectCatgory() {
      this.$emit('selectCatgory', { data: this.data })
    },
    showHideCategory() {
      this.$emit('showHideCategory', this.data.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.vAdminMenuCategoryItem {
  display: flex;
  &__info {
    background: #f7f8fa;
    border-radius: 7px;
    width: 100%;
    padding: 12px;
    border: 1px solid transparent;
    cursor: move;
    &.active {
      border-color: #d5dbf1;
      background: #f8f9fe;
    }
    &-top {
      display: flex;
      align-items: center;
      font-weight: 400;
      color: #80869a;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 4px;
      svg {
        margin-right: 8px;
      }
    }
    &__bottom {
      color: #010d35;
      font-size: 14px;
      font-weight: 500;
    }
  }
  &__btn {
    width: 32px;
    min-width: 32px;
    margin-left: 8px;
    background: #f7f8fa;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.active {
      background: #f8f9fe;
    }

    .vAdminMenuBtn {
      height: 100%;
    }
  }
}
</style>
