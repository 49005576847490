<template>
  <div class="vAdminMenuAside">
    <vButton
      type="button"
      @click="updateMenu"
    >
      Обновить меню
    </vButton>
    <div class="vAdminMenuAside__title">
      Категории
    </div>
    <div class="vAdminMenuAside__categories">
      <div class="vAdminMenuAside__help">
        <vAdminMenuHelp />
      </div>
      <div
        class="vAdminMenuAside__active"
        :class="{ active: hideInaciveCategories }"
      >
        <vCheckbox
          id="showActive"
          :value="hideInaciveCategories"
          label="Показать активные для гостя"
          position-label="start"
          @click="toggleInaciveCategories"
          @clickHandler="toggleInaciveCategories"
        />
      </div>
      <div class="vAdminMenuAside__list">
        <draggable
          v-model="categoriesSortable"
          tag="transition-group"
          item-key="id"
          :move="categorySort"
          :component-data="getComponentData"
          @end="onEnd"
        >
          <template #item="{ element }">
            <div
              :id="element.id"
              class="vAdminMenuAside__category"
              :class="{ hide: hideInaciveCategories && element.hidden }"
            >
              <vAdminMenuCategoryItem
                :active="element.id === selectCatgoryID"
                :data="element"
                @selectCatgoryEdit="selectCatgoryEdit"
                @selectCatgory="selectCatgory"
                @showHideCategory="showHideCategory"
              />
            </div>
          </template>
        </draggable>
      </div>
    </div>
    <vAdminMenuEditModal
      v-if="editCategory"
      :data="editCategory"
      @selectCatgoryEdit="selectCatgoryEdit"
      @saveChangesCategory="saveChangesCategory"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'
import vButton from '@/components/v-button'
import vCheckbox from '@/components/v-checkbox'
import vAdminMenuHelp from '@/components/adminMenu/v-admin-menu-help'
import vAdminMenuCategoryItem from '@/components/adminMenu/v-admin-menu-category-item'
import vAdminMenuEditModal from '@/components/adminMenu/v-admin-menu-edit-modal'
export default {
  name: 'VAdminMenuAside',
  components: {
    vButton,
    vAdminMenuHelp,
    vCheckbox,
    vAdminMenuCategoryItem,
    draggable,
    vAdminMenuEditModal,
  },
  props: {
    categories: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectCatgoryID: {
      type: Number,
      required: true,
      default: null,
    },
    stateHideInaciveCategories: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['updateMenu', 'selectCatgory', 'changeStateInaciveCategories'],
  data() {
    return {
      categoriesSortable: [],
      hideInaciveCategories: false,
      editCategory: null,
      dragIndex: null,
      dragElementId: null,
      lastID: null,
    }
  },
  computed: {
    ...mapGetters('user', ['USER_DATA', 'GET_AUTHENTIFICATED']),
  },
  watch: {
    hideInaciveCategories() {
      this.$emit('changeStateInaciveCategories', this.hideInaciveCategories)
    },
  },
  mounted() {
    this.categoriesSortable = this.categories
    this.hideInaciveCategories = this.stateHideInaciveCategories
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('menuAdmin', [
      'EDIT_CATEGORY',
      'CATEGORY_VISIBLE',
      'CATEGORY_SORT',
    ]),
    updateMenu() {
      this.$emit('updateMenu')
    },
    toggleInaciveCategories() {
      this.hideInaciveCategories = !this.hideInaciveCategories
    },
    selectCatgory(payload) {
      this.$emit('selectCatgory', payload)
    },
    selectCatgoryEdit(data) {
      this.editCategory = data
    },
    categorySort(e) {
      this.dragElementId = e.draggedContext.element.id
    },

    test() {
      this.testTest = !this.testTest
    },

    async onEnd() {
      this.TOGGLE_PRELOADER(true)

      let idx
      this.categoriesSortable.forEach((i, index) => {
        if (i.id === this.dragElementId) idx = index
      })

      const dragElement = this.categoriesSortable[idx]
      let listPosition = null

      const before = this.categoriesSortable[idx + 1]?.list_position - 1
      const after = this.categoriesSortable[idx - 1]?.list_position + 1

      if (!dragElement) {
        this.TOGGLE_PRELOADER(false)
        return
      }

      if (this.categoriesSortable[idx + 1]) {
        listPosition = this.categoriesSortable[idx + 1].list_position - 1
      } else {
        listPosition = this.categoriesSortable[idx - 1].list_position + 1
      }

      await this.CATEGORY_SORT({
        token: this.GET_AUTHENTIFICATED,
        ourName: dragElement.our_name,
        id: dragElement.id,
        listPosition,
      })

      const element = document.getElementById(dragElement.id)
      element.scrollIntoView({ block: 'end' })

      setTimeout(() => {
        this.TOGGLE_PRELOADER(false)
      }, 500)
    },

    async showHideCategory(id) {
      this.TOGGLE_PRELOADER(true)
      await this.CATEGORY_VISIBLE({
        token: this.GET_AUTHENTIFICATED,
        id,
      })
      this.TOGGLE_PRELOADER(false)
    },
    async saveChangesCategory(ourName) {
      this.TOGGLE_PRELOADER(true)
      await this.EDIT_CATEGORY({
        token: this.GET_AUTHENTIFICATED,
        id: this.editCategory.id,
        list_position: this.editCategory.list_position,
        our_name: ourName,
      })
      this.TOGGLE_PRELOADER(false)
    },
  },
}
</script>

<style lang="scss">
.vAdminMenuAside {
  height: 100%;
  &__title {
    font-weight: 500;
    color: #010d35;
    font-size: 20px;
    margin-bottom: 12px;
  }
  &__categories {
    border: 1px solid #edeef1;
    border-radius: 12px;
    background: #fff;
    padding: 12px;
    height: calc(100% - 100px);
  }
  &__category {
    list-style: none;
    list-style-type: none;
    &.hide {
      display: none;
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &.sortable-chosen {
      border: 1px solid #6764ff;
      border-radius: 7px;
    }
  }
  &__list {
    height: calc(100% - 100px);
    overflow: auto;
  }
  &__help {
    margin-bottom: 17px;
  }
  &__active {
    margin-bottom: 12px;
    border-radius: 6px;
    padding: 8px;
    height: 36px;
    &.active {
      background: #6764ff;
      color: #fff;
      .vCheckbox__custom {
        background-color: #fff;
      }
    }
  }
}
</style>
