<template>
  <svg
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9997 13.8089C21.3164 13.2964 20.9142 12.4921 20.9142 11.6379C20.9142 10.7838 21.3164 9.97944 21.9997 9.46694C22.6831 8.95445 23.0852 8.15013 23.0852 7.29597C23.0852 6.44181 22.6831 5.6375 21.9997 5.125"
      stroke="#80869A"
      stroke-width="2.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.5987 13.8089C28.9154 13.2964 28.5132 12.4921 28.5132 11.6379C28.5132 10.7838 28.9154 9.97944 29.5987 9.46694C30.282 8.95445 30.6842 8.15013 30.6842 7.29597C30.6842 6.44181 30.282 5.6375 29.5987 5.125"
      stroke="#80869A"
      stroke-width="2.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.4015 13.8089C13.7181 13.2964 13.316 12.4921 13.316 11.6379C13.316 10.7838 13.7181 9.97944 14.4015 9.46694C15.0848 8.95445 15.487 8.15013 15.487 7.29597C15.487 6.44181 15.0848 5.6375 14.4015 5.125"
      stroke="#80869A"
      stroke-width="2.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="39.3563"
      y="28.0254"
      width="34.7126"
      height="6.50861"
      rx="2.16954"
      transform="rotate(-180 39.3563 28.0254)"
      stroke="#80869A"
      stroke-width="2.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.89815 28.0273C7.89815 34.0183 12.7548 38.875 18.7458 38.875L22.0001 38.875L25.2545 38.875C31.2455 38.875 36.1021 34.0183 36.1021 28.0273"
      stroke="#80869A"
      stroke-width="2.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconLotPlaceholder',
}
</script>

<style scoped></style>
