<template>
  <div class="vAdminMenuEditModal">
    <div
      class="vAdminMenuEditModal__overlay"
      @click="selectCatgoryEdit"
    />
    <div class="vAdminMenuEditModal__content">
      <div class="vAdminMenuEditModal__input">
        <div class="vAdminMenuEditModal__placeholder">
          Название на кассе
        </div>
        <div class="vAdminMenuEditModal__name">
          {{ data.name }}
        </div>
      </div>
      <div class="vAdminMenuEditModal__input-custom">
        <div class="vAdminMenuEditModal__input-custom-wrapper">
          <input
            id="ourName"
            v-model="ourName"
            :class="{ active: ourName }"
          >
          <div class="vAdminMenuEditModal__input-custom-label">
            Название у гостя
          </div>
        </div>
      </div>
      <div
        class="vAdminMenuEditModal__btn"
        :class="{ disabled: ourName === '-' }"
      >
        <vButton @click="saveChangesCategory">
          Сохранить
        </vButton>
      </div>
      <vButton
        theme="gray"
        @click="selectCatgoryEdit"
      >
        Отменить
      </vButton>
    </div>
  </div>
</template>

<script>
import vButton from '@/components/v-button'
export default {
  name: 'VAdminMenuEditModal',
  components: {
    vButton,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({
        name: 'ЛИМОНАДЫ',
        our_name: '-',
      }),
    },
  },
  emits: ['selectCatgoryEdit', 'saveChangesCategory'],
  data() {
    return {
      ourName: '',
    }
  },
  mounted() {
    this.ourName = this.data.our_name !== '-' ? this.data.our_name : ''
  },
  methods: {
    selectCatgoryEdit() {
      this.$emit('selectCatgoryEdit', null)
    },
    saveChangesCategory() {
      let req = ''
      if (!this.ourName) {
        req = '-'
      } else {
        req = this.ourName
      }
      this.$emit('saveChangesCategory', req)
      this.selectCatgoryEdit()
    },
  },
}
</script>

<style lang="scss" scoped>
.vAdminMenuEditModal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &__overlay {
    position: absolute;
    left: 0;
    background: rgba(220, 222, 227, 0.9);
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  &__content {
    background: #fff;
    position: relative;
    z-index: 2;
    padding: 12px 12px 0 12px;
    border: 1px solid #e6e7eb;
    border-radius: 18px;
    width: 100%;
    max-width: 343px;
  }
  &__input {
    border: 1px solid #e6e7eb;
    border-radius: 12px;
    height: 52px;
    padding: 6px 12px;
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
  }
  &__placeholder {
    position: absolute;
    left: 12px;
    top: 8px;
    color: #80869a;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }
  &__name {
    color: #010d35;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  &__btn {
    position: relative;
    &.disabled {
      opacity: 0.2;
      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
  &__input-custom {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    border: none;
    width: 100%;
    input {
      width: 100%;
      padding: 18px 42px 10px 12px;
      background: transparent;
      border: none;
      font-family: 'PTRootUI';
      line-height: 24px;
      font-weight: 500;
      font-size: 16px;
      position: relative;
      z-index: 2;
      color: #010d35;
      outline: none;
      &:focus + .vAdminMenuEditModal__input-custom-label,
      &.active + .vAdminMenuEditModal__input-custom-label {
        top: 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        z-index: 2;
      }
      &:disabled {
        color: #353841;
      }
    }
    &-label {
      position: absolute;
      top: 50%;
      left: 12px;
      font-weight: 500;
      line-height: 20px;
      font-size: 16px;
      color: #80869a;
      transform: translateY(-50%);
      transition: all 0.3s;
      z-index: 1;
    }
    &-wrapper {
      position: relative;
      background: #fff;
      border: 1px solid #e6e7eb;
      border-radius: 12px;
      overflow: hidden;
      width: 100%;
      &.disabled {
        background: #e6e7eb;
      }
    }
  }
}
</style>
