<template>
  <div class="vAdmiMenuTable">
    <div class="vAdmiMenuTable__header">
      <div
        v-show="!gridView.value"
        class="vAdmiMenuTable__header-item"
      >
        №
      </div>
      <div class="vAdmiMenuTable__header-item">
        {{ gridView.value ? 'Позиции' : 'Фото' }}
      </div>
      <div
        v-show="!gridView.value"
        class="vAdmiMenuTable__header-item"
      >
        Название на кассе
      </div>
      <div
        v-show="!gridView.value"
        class="vAdmiMenuTable__header-item"
      >
        Название у гостя
      </div>
      <div
        v-show="!gridView.value"
        class="vAdmiMenuTable__header-item"
      >
        Состав
      </div>
      <div
        v-show="!gridView.value"
        class="vAdmiMenuTable__header-item"
      >
        Описание
      </div>
      <div
        v-show="!gridView.value"
        class="vAdmiMenuTable__header-item"
      >
        Цена
      </div>
      <div
        v-show="!gridView.value"
        class="vAdmiMenuTable__header-item"
      >
        Вес
      </div>
      <div
        v-show="!gridView.value"
        class="vAdmiMenuTable__header-item"
      >
        Ккал
      </div>
      <div
        v-show="!gridView.value"
        class="vAdmiMenuTable__header-item"
      >
        Метки
      </div>
      <div
        v-show="!gridView.value"
        class="vAdmiMenuTable__header-item"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.0785 8.31198C1.97383 8.11798 1.97383 7.88265 2.0785 7.68865C3.33983 5.35598 5.66983 3.33398 7.99983 3.33398C10.3298 3.33398 12.6598 5.35598 13.9212 7.68932C14.0258 7.88332 14.0258 8.11865 13.9212 8.31265C12.6598 10.6453 10.3298 12.6673 7.99983 12.6673C5.66983 12.6673 3.33983 10.6453 2.0785 8.31198V8.31198Z"
            stroke="#6764FF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.41421 6.58579C10.1953 7.36683 10.1953 8.63316 9.41421 9.41421C8.63316 10.1953 7.36683 10.1953 6.58579 9.41421C5.80474 8.63316 5.80474 7.36683 6.58579 6.58579C7.36683 5.80474 8.63316 5.80474 9.41421 6.58579Z"
            stroke="#6764FF"
            stroke-width="1.429"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div
      v-if="!selectCatgoryData.length"
      class="vAdmiMenuTable__empty"
    >
      <span v-if="!selectCatgoryName.value.name">
        Выберите категорию <br>
        для отображения <br>
        списка позиций
      </span>
      <span v-else>
        Нет элементов <br>
        для показа
      </span>
    </div>
    <div
      v-else
      ref="list"
      class="vAdmiMenuTable__list"
      :class="{ grid: gridView.value }"
    >
      <!-- Grid -->
      <template
        v-for="item in productsSortable"
        :key="item.id"
      >
        <div
          v-if="!item.hidden"
          class="vAdmiMenuTable__item-grid"
        >
          <div class="vAdmiMenuTable__item-img">
            <img
              v-if="item.product_picture"
              :src="item.product_picture.versions.optimized"
              alt="item.name"
            >
          </div>
          <div class="vAdmiMenuTable__item-name">
            <span>
              {{
                item.ourName && item.ourName !== 'null' && item.ourName !== '-'
                  ? item.ourName
                  : item.name
              }}
            </span>
          </div>
          <div class="vAdmiMenuTable__item-info">
            <div class="vAdmiMenuTable__item-price">
              {{ item.price / 100 }} &#x20bd;
            </div>
            <div class="vAdmiMenuTable__item-gramm">
              {{ item.gram || '-' }}
            </div>
          </div>
        </div>
      </template>

      <!-- Table -->
      <draggable
        v-model="productsSortable"
        tag="transition-group"
        item-key="id"
        :move="categorySort"
        :component-data="getComponentData"
        @end="onEnd"
      >
        <template #item="{ element }">
          <div
            class="vAdmiMenuTable__item"
            :class="{ edit: editID === element.id }"
          >
            <div class="vAdmiMenuTable__item-num">
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.16666 8H12.8333"
                  stroke="#80869A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.16666 10.666H12.8333"
                  stroke="#80869A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.16666 5.33301H12.8333"
                  stroke="#80869A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {{ element.order }}
            </div>
            <div
              class="vAdmiMenuTable__item-img"
              :class="{
                'vAdmiMenuTable__img-placeholder': !element.product_picture,
              }"
            >
              <img
                v-if="preview && editID === element.id"
                :src="preview"
                alt="preview"
                class="vAdmiMenuTable__item-preview"
                @click="showPicture(element)"
              >
              <img
                v-if="element.product_picture"
                :src="element.product_picture.versions.optimized"
                :alt="element.name"
                @click="showPicture(element)"
              >
              <VIconLotPlaceholder
                v-else
                class="vAdmiMenuTable__item-placeholder"
              />
              <div
                class="vAdmiMenuTable__item-img-overlay"
                :class="{ empty: !element.product_picture && !preview }"
              >
                <svg
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.59208 7.44031C10.128 6.46628 10.711 5.81515 11.5762 5.47081C13.1615 4.84044 18.8394 4.84044 20.4235 5.47094C21.2881 5.81509 21.8714 6.46611 22.4077 7.43995L23.0107 8.59615C23.0245 8.61906 23.0372 8.6392 23.0485 8.65654L23.0997 8.72654C23.3745 9.02588 23.8342 9.20671 24.1377 9.20671C27.007 9.20671 29.3337 11.5328 29.3337 14.4014V22.128C29.3337 25.787 26.3659 28.7547 22.707 28.7547H9.29366C5.6338 28.7547 2.66699 25.7874 2.66699 22.128V14.4014C2.66699 11.5328 4.99363 9.20671 7.86299 9.20671C8.16536 9.20671 8.62576 9.02562 8.90091 8.72654C8.90641 8.72055 8.91358 8.71164 8.92226 8.69983L8.99082 8.59465L9.59208 7.44031ZM20.6559 8.40482C20.3226 7.7997 20.0214 7.4635 19.6839 7.32916L19.5247 7.27627C18.1526 6.88842 13.3795 6.90605 12.3155 7.32916C11.9781 7.46343 11.6772 7.79945 11.3443 8.40445L10.8312 9.39658L10.7049 9.62516C10.5995 9.80053 10.494 9.94856 10.3736 10.0798C9.69831 10.8138 8.69932 11.2067 7.86299 11.2067L7.6442 11.2141C5.98133 11.3265 4.66699 12.711 4.66699 14.4014V22.128C4.66699 24.6829 6.73844 26.7547 9.29366 26.7547H22.707C25.2614 26.7547 27.3337 24.6824 27.3337 22.128V14.4014C27.3337 12.6375 25.9025 11.2067 24.1377 11.2067L23.9055 11.197C23.1208 11.1324 22.2394 10.7469 21.6262 10.0789C21.5074 9.94942 21.4025 9.80243 21.2977 9.62854C21.2827 9.60359 21.264 9.57075 21.2425 9.5319L21.0877 9.24157L20.6559 8.40482Z"
                    fill="white"
                  />
                  <path
                    d="M23.3395 12.332C24.0759 12.332 24.6728 12.929 24.6728 13.6654C24.6728 14.3491 24.1581 14.9127 23.495 14.9897L23.3395 14.9987C22.5911 14.9987 21.9941 14.4017 21.9941 13.6654C21.9941 12.9816 22.5089 12.418 23.172 12.341L23.3395 12.332Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.7612 18.5023C10.7612 15.6087 13.1063 13.2637 15.9999 13.2637C18.8935 13.2637 21.2386 15.6087 21.2386 18.5023C21.2386 21.396 18.8935 23.741 15.9999 23.741C13.1063 23.741 10.7612 21.396 10.7612 18.5023ZM19.2386 18.5023C19.2386 16.7133 17.7889 15.2637 15.9999 15.2637C14.2108 15.2637 12.7612 16.7133 12.7612 18.5023C12.7612 20.2914 14.2108 21.741 15.9999 21.741C17.7889 21.741 19.2386 20.2914 19.2386 18.5023Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div class="vAdmiMenuTable__item-name">
              <span>
                {{ element.name }}
              </span>
            </div>
            <div
              class="vAdmiMenuTable__item-name vAdmiMenuTable__item-our-name"
            >
              <span v-if="editID != element.id">{{
                element.ourName && element.ourName !== 'null'
                  ? element.ourName
                  : '-'
              }}</span>
              <input
                v-else
                v-model="editingOurName"
                placeholder="-"
                class="vAdmiMenuTable__input"
              >
            </div>
            <div
              class="vAdmiMenuTable__item-name vAdmiMenuTable__item-ingredients"
            >
              <span>
                {{ element.product_info?.ingredients }}
              </span>
            </div>
            <div
              class="vAdmiMenuTable__item-name vAdmiMenuTable__item-ingredients"
            >
              <span>
                {{ element.product_info?.internal_description }}
              </span>
            </div>
            <div class="vAdmiMenuTable__item-price">
              {{ element.price / 100 }}
            </div>
            <div
              class="vAdmiMenuTable__item-gramm"
              v-html="getElemWeight(element)"
            />
            <div class="vAdmiMenuTable__item-gramm">
              {{ element.product_info?.calories || '-' }}
            </div>
            <div class="vAdmiMenuTable__item-mark">
              <span v-if="getElementMark(element)">
                {{ getElementMark(element) }}
              </span>
            </div>

            <div class="vAdmiMenuTable__item-btn">
              <vAdminMenuBtn
                type="edit"
                @click="edit(element)"
              />
            </div>
            <div class="vAdmiMenuTable__item-btn --eye">
              <vAdminMenuBtn
                :type="element.hidden ? 'eyeClose' : 'eye'"
                :active="!element.hidden && true"
                @click="visible(element.id)"
              />
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div
      v-show="delModal"
      ref="modal"
      class="vAdmiMenuTable__modal"
    >
      <div
        class="vAdmiMenuTable__modal-close-overlay"
        @click="delModal = false"
      />
      <div class="vAdmiMenuTable__modal-close-content">
        <div class="vAdmiMenuTable__modal-close-title">
          Предупреждение
          <vCloseButton @click="delModal = false" />
        </div>
        <div class="vAdmiMenuTable__modal-close-description">
          Вы действительно хотите удалить фото блюда?
        </div>
        <div class="vAdmiMenuTable__modal-close-buttons">
          <vButton @click="save(delItem, true)">
            Удалить
          </vButton>
          <vButton
            theme="gray"
            @click="delModal = false"
          >
            Отменить
          </vButton>
        </div>
      </div>
    </div>

    <VAdminMenuLotEditModal
      v-if="editItemData"
      :data="editItemData"
      @selectCatgoryEdit="selectCatgoryEdit"
      @saveChangesCategory="saveChangesCategory"
      @save="updateItem"
      @close="closeEditModal"
      @showPicture="showPicture($event)"
    />

    <div
      v-show="showedPicture"
      class="vAdmiMenuTable__picture-modal"
    >
      <div
        class="vAdmiMenuTable__picture-modal-overlay"
        @click="showedPicture = null"
      />
      <div class="vAdmiMenuTable__picture-modal-content">
        <div class="vAdmiMenuTable__picture-modal-title">
          <span>{{ titleForPreview }}</span>
          <vCloseButton @click="showedPicture = null" />
        </div>
        <img
          :src="showedPicture"
          alt="preview"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'
import vAdminMenuBtn from '@/components/adminMenu/v-admin-menu-btn'
import vCloseButton from '@/components/v-close-button'
import vButton from '@/components/v-button'
import VAdminMenuLotEditModal from '@/components/adminMenu/v-admin-menu-lot-edit-modal'
import VIconLotPlaceholder from '@/assets/images/icons/v-icon-lot-placeholder'
export default {
  name: 'VAdmiMenuTable',
  components: {
    VIconLotPlaceholder,
    VAdminMenuLotEditModal,
    vAdminMenuBtn,
    vCloseButton,
    vButton,
    draggable,
  },
  inject: ['selectCatgoryName', 'gridView'],
  props: {
    selectCatgoryData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: ['togglePreloader', 'resetSelected', 'selectCatgory'],
  data() {
    return {
      editID: null,
      editItemData: null,
      editingOurName: null,
      product_picture: null,
      preview: null,
      delModal: false,
      delItem: null,
      productsSortable: [],
      dragIndex: null,
      showedPicture: null,
      titleForPreview: null,
      dragElementId: null,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
  },
  watch: {
    selectCatgoryData() {
      this.productsSortable = this.selectCatgoryData
    },
  },
  mounted() {
    document.addEventListener('click', this.reset)
  },
  unmounted() {
    document.removeEventListener('click', this.reset)
  },
  methods: {
    ...mapActions('menuAdmin', [
      'PRODUCT_UPDATE',
      'PRODUCT_VISIBLE',
      'PRODUCT_SORT',
    ]),
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),

    showPicture(data) {
      this.titleForPreview = null
      if (data.product_picture) {
        if (data.product_picture.versions) {
          this.titleForPreview =
            data.ourName && data.ourName !== 'null' && data.ourName !== '-'
              ? data.ourName
              : data.name
          this.showedPicture =
            data.product_picture.versions.original ||
            data.product_picture.versions.optimized
        }
      }
    },
    reset(e) {
      if (this.editID) this.$refs['inputFile' + this.editID].value = null
      const withinBoundaries = e.composedPath().includes(this.$refs.list)
      const modal = e.composedPath().includes(this.$refs.modal)
      if (!withinBoundaries && !modal) {
        this.editID = null
        this.editingOurName = null
        this.product_picture = null
        this.preview = null
      }
    },
    del(item) {
      this.delItem = item
      if (this.preview) {
        this.preview = null
        return
      }
      this.delModal = true
    },
    edit(item) {
      this.editItemData = item
    },
    uploadImage(event) {
      const file = event.target.files[0]
      this.product_picture = event.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        const { result: base64 } = reader
        this.preview = base64
      }

      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    },
    async save(value, del = false) {
      this.delModal = false
      this.preview = null
      this.$emit('togglePreloader')
      this.editID = null

      await this.PRODUCT_UPDATE({
        token: this.GET_AUTHENTIFICATED,
        id: value.id,
        ourName: this.editingOurName ? this.editingOurName : '',
        product_picture: this.product_picture,
        delete_picture: del,
      })

      this.product_picture = null
      this.$emit('togglePreloader')
    },
    async visible(id) {
      this.$emit('togglePreloader')
      await this.PRODUCT_VISIBLE({
        token: this.GET_AUTHENTIFICATED,
        id,
      })
      this.$emit('togglePreloader')
    },
    categorySort(e) {
      this.dragElementId = e.draggedContext.element.id
    },
    async onEnd() {
      this.TOGGLE_PRELOADER(true)

      let idx
      this.productsSortable.forEach((i, index) => {
        if (i.id === this.dragElementId) idx = index
      })

      const dragElement = this.productsSortable[idx]
      let listPosition = null

      const before = this.productsSortable[idx + 1]?.list_position - 1
      const after = this.productsSortable[idx - 1]?.list_position + 1

      if (!before && !after) {
        this.TOGGLE_PRELOADER(false)
        return
      }
      if (this.productsSortable[idx + 1]) {
        listPosition = this.productsSortable[idx + 1].list_position - 1
      } else {
        listPosition = this.productsSortable[idx - 1].list_position + 1
      }

      if (dragElement) {
        await this.PRODUCT_SORT({
          ...dragElement,
          token: this.GET_AUTHENTIFICATED,
          list_position: listPosition,
        })
        this.$emit('resetSelected')
        this.$emit('selectCatgory', { data: null, repeat: true })
      }
      this.TOGGLE_PRELOADER(false)
    },
    closeEditModal() {
      this.editItemData = null
    },
    getElemWeight(elem) {
      let result = '-'
      if (elem.product_info?.count) {
        result = `${elem.product_info?.count}`

        if (elem.product_info?.unit) result += ` ${elem.product_info.unit}`
      }
      return result
    },
    getElementMark(elem) {
      if (elem.product_info?.labels) {
        if (elem.product_info.labels.vegan) return 'Веган'
        if (elem.product_info.labels.spicy) return 'Острое'
        if (elem.product_info.labels.gluten_free) return 'Безглютеновое'
        if (elem.product_info.labels.dietary) return 'Диетическое'
      }

      return ''
    },
    updateItem(payload) {
      const index = this.productsSortable.findIndex((e) => e.id === payload.id)

      if (index !== -1) {
        this.productsSortable.splice(index, 1, payload)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.vAdmiMenuTable {
  height: calc(100vh - 360px);
  &__input {
    border: 1px solid #cccfd7;
    border-radius: 8px;
    background: #fff;
    height: 100%;
    width: 100%;
    padding: 8px;
  }
  &__item-preview + img {
    display: none;
  }
  &__item-placeholder {
    width: 32px;
    height: 32px;
  }
  &__upload {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }
  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #edeef1;
    &-item {
      display: flex;
      align-items: center;
      color: #80869a;
      font-size: 16px;
      &:first-child {
        width: 19px;
        margin-right: 12px;
      }
      &:nth-child(2) {
        width: 51px;
        margin-right: 8px;
      }
      &:nth-child(3) {
        width: 108px;
        margin-right: 12px;
      }
      &:nth-child(4) {
        width: 108px;
        margin-right: 12px;
      }
      &:nth-child(5) {
        width: 108px;
        margin-right: 12px;
      }
      &:nth-child(6) {
        width: 108px;
        margin-right: 12px;
      }
      &:nth-child(7) {
        width: 96px;
        margin-right: 6px;
      }
      &:nth-child(8) {
        width: 53px;
        margin-right: 12px;
      }
      &:nth-child(9) {
        width: 53px;
        margin-right: 12px;
      }
      &:nth-child(10) {
        width: 91px;
        justify-content: flex-end;
        margin-right: 12px;
        margin-left: auto;
      }
      &:last-child {
        min-width: 76px;
        justify-content: flex-end;
        padding-right: 8px;
      }
    }
  }
  &__ok {
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    background: linear-gradient(223.61deg, #547aff 0%, #413dff 100%);
    border-radius: 6px;
    width: 36px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: auto 0;
    cursor: pointer;
  }
  &__empty {
    background: #f7f8fa;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #80869a;
    font-size: 16px;
    line-height: 20px;
    height: calc(100% - 70px);
  }
  &__list {
    margin: 0;
    overflow: auto;
    max-height: calc(100vh - 430px);
    padding-top: 20px;
  }
  &__item-img-del {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fff;
    z-index: 2;
    top: -10px;
    right: -10px;
    border: 1.5px solid #edeef1;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background: #ec4e4e;
      height: 1.5px;
      width: 12px;
    }
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      background: #ec4e4e;
      height: 1.5px;
      width: 12px;
    }
  }
  &__item {
    display: flex;
    cursor: move;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    & > div {
      display: flex;
      align-items: center;
      &:nth-last-child(2) {
        margin-left: auto;
      }
      &:last-child {
        margin-left: 6px;
      }
    }
    &.edit {
      background: #f8f9fe;
      border-radius: 7px;
      .vAdmiMenuTable__item-img-overlay {
        display: flex;
      }
    }

    &.sortable-chosen {
      border: 1px solid #6764ff;
      border-radius: 7px;
    }
  }
  &__item-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    min-width: 60px;
    height: 60px;
    margin-right: 8px;
    border-radius: 8px;
    background: #f7f8fa;
    position: relative;
    cursor: pointer;
    &-overlay {
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      display: none;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      &.empty {
        background: #cccfd7;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  &__img-placeholder {
    border: 1px solid #e6e7eb;
  }
  &__item-num {
    width: 19px;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
  }
  &__item-btn {
    align-items: stretch !important;
    cursor: pointer;
  }
  &__item-name {
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    width: 108px;
    margin-right: 12px;

    span {
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow-y: hidden;
      word-wrap: break-word;
    }
  }
  &__item-our-name {
    width: 108px;
  }
  &__item-price {
    font-size: 16px;
    font-weight: 500;
    width: 96px;
    margin-right: 6px;
  }
  &__item-gramm {
    font-size: 16px;
    font-weight: 500;
    width: 53px;
    margin-right: 12px;
  }
  &__item-mark {
    font-size: 11px;
    color: #4e5672;
    font-weight: 500;
    width: 99px;
    margin-right: 12px;
    margin-left: auto;

    span {
      display: block;
      margin-left: auto;
      padding: 4px;
      border: 1px solid #e6e7eb;
      border-radius: 6px;
    }
  }
  &__item-ingredients {
    color: #4e5672;
  }
  &__item-grid {
    display: none;
    border: 1px solid #e6e7eb;
    border-radius: 12px;
    background: #fff;
    margin-left: 11px;
    width: calc(20% - 11px);
    padding: 10px;
    margin-bottom: 20px;
    flex-direction: column;
  }
  &__modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    &-close {
      &-overlay {
        background: #dcdee3;
        opacity: 0.9;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
      &-content {
        background: #fff;
        position: relative;
        z-index: 2;
        width: 100%;
        max-width: 666px;
        border-radius: 12px;
        padding: 24px 16px;
        position: relative;
      }
      &-title {
        color: #010d35;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 24px;
        line-height: 25px;
        text-align: center;
      }
      &-description {
        min-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 24px;
      }
      &-buttons {
        display: flex;
        & > *:first-child {
          margin-right: 4px;
          margin-bottom: 0;
        }
        & > *:last-child {
          margin-left: 4px;
          margin-bottom: 0;
        }
      }
    }
  }
  &__picture-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    img {
      width: 100%;
      object-fit: cover;
      height: calc(100% - 65px);
    }
    &-overlay {
      position: absolute;
      left: 0;
      background: rgba(220, 222, 227, 0.9);
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    &-content {
      background: #fff;
      position: relative;
      z-index: 2;
      border: 1px solid #e6e7eb;
      border-radius: 18px;
      width: 100%;
      max-width: 515px;
      height: 515px;
      padding: 0 12px 12px 12px;
    }
    &-title {
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 24px;
      width: calc(100% - 35px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      span {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.grid {
  display: flex;
  justify-content: flex-start;
  margin-left: -11px;
  width: calc(100% + 11px);
  flex-wrap: wrap;
  .vAdmiMenuTable {
    &__item {
      display: none;
      &-img {
        height: 146px;
        width: 100%;
        margin-right: 0;
        margin-bottom: 5px;
      }
      &-name {
        margin-right: 0;
        margin-bottom: 5px;
        width: 100%;
      }
      &-grid {
        display: flex;
      }
      &-price {
        font-size: 15px;
        margin-right: 2px;
      }
      &-gramm {
        margin-right: 0;
        text-align: right;
      }
      &-info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
      }
    }
  }
}
</style>
