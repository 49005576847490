<template>
  <div class="vAdminMenuCategoryShield">
    <div class="vAdminMenuCategoryShield__title">
      Категория:
    </div>
    <div class="vAdminMenuCategoryShield__group">
      <div class="vAdminMenuCategoryShield__el">
        <div class="vAdminMenuCategoryShield__el-title">
          Название на кассе
        </div>
        <div class="vAdminMenuCategoryShield__el-name">
          {{ selectCatgoryName.value.name || '-' }}
        </div>
      </div>
      <div class="vAdminMenuCategoryShield__el">
        <div class="vAdminMenuCategoryShield__el-title">
          Название у гостя
        </div>
        <div class="vAdminMenuCategoryShield__el-name">
          {{ selectCatgoryName.value.our_name || '-' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VAdminMenuCategoryShield',
  inject: ['selectCatgoryName'],
}
</script>

<style lang="scss" scoped>
.vAdminMenuCategoryShield {
  &__title {
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 8px;
    color: #010d35;
  }
  &__group {
    display: flex;
    align-items: flex-start;
    border: 1px solid #edeef1;
    border-radius: 12px;
    padding: 8px;
  }
  &__el {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 250px;
    &:not(:first-child) {
      margin-left: 16px;
    }
  }
  &__el-title {
    font-weight: 400;
    font-size: 12px;
    color: #80869a;
    margin-bottom: 8px;
  }
  &__el-name {
    background: #f7f8fa;
    border-radius: 7px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
}
</style>
