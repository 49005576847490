<template>
  <div class="vAdminMenuViews">
    <div class="vAdminMenuViews__text">
      Вид:
    </div>

    <div class="vAdminMenuViews__view">
      <div
        class="vAdminMenuViews__el"
        :class="{ active: !gridView.value }"
        @click="toggleGridView(false)"
      >
        <vIconList />
      </div>

      <div
        class="vAdminMenuViews__el"
        :class="{ active: gridView.value }"
        @click="toggleGridView(true)"
      >
        <vIconGrid />
      </div>
    </div>
  </div>
</template>

<script>
import vIconList from '@/assets/images/icons/v-icon-list'
import vIconGrid from '@/assets/images/icons/v-icon-grid'
export default {
  name: 'VAdminMenuViews',
  components: {
    vIconList,
    vIconGrid,
  },
  inject: ['gridView'],
  emits: ['toggleGridView', 'resetEditing'],
  methods: {
    toggleGridView(val) {
      this.$emit('resetEditing')
      this.$emit('toggleGridView', val)
    },
  },
}
</script>

<style lang="scss">
.vAdminMenuViews {
  display: flex;
  align-items: center;
  height: 56px;
  border: 1px solid #e6e7eb;
  border-radius: 12px;
  padding: 0 16px;
  // @media(max-width: 1180px) {
  //   width: 100%;
  //   margin-bottom: 12px;
  //   justify-content: space-between;
  // }
  &__text {
    margin-right: 12px;
  }
  &__checkbox {
    width: 82px;
    height: 40px;
    background: #000;
  }
  &__view {
    min-width: 82px;
    padding: 2px;
    background: #f7f8fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 42px;
    overflow: hidden;
  }
  &__el {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: #c9c9d0;
    stroke: #c9c9d0;
    cursor: pointer;
    &.active {
      background: #fff;
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
      fill: #000000;
      stroke: #000000;
    }
  }
}
</style>
