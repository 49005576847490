<template>
  <div
    class="vAdminMenuBtn"
    :class="[
      { active: active },
      { '--eye': type === 'eye' || type === 'eyeClose' },
      { '--edit': type === 'edit' },
    ]"
    @click="handleClick"
  >
    <svg
      v-if="type === 'edit'"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.72067 11.3333H3.33333C2.59667 11.3333 2 10.7367 2 10V3.33333C2 2.59667 2.59667 2 3.33333 2H10C10.7367 2 11.3333 2.59667 11.3333 3.33333V5.33333"
        stroke="#80869A"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 5H8.33333"
        stroke="#80869A"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66602 8.33333V5"
        stroke="#80869A"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.4147 13.7857L14.4513 9.74898C14.738 9.46232 14.738 8.99832 14.4513 8.71232L13.288 7.54898C13.0013 7.26232 12.5373 7.26232 12.2513 7.54898L8.21467 11.5857C8.07667 11.7237 8 11.9097 8 12.1037V14.0003H9.89667C10.0907 14.0003 10.2767 13.9237 10.4147 13.7857Z"
        stroke="#80869A"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <vIconMenuEye v-if="type === 'eye'" />
    <vIconMenuEyeClose v-if="type === 'eyeClose'" />
  </div>
</template>

<script>
import vIconMenuEye from '@/assets/images/icons/v-icon-menu-eye.vue'
import vIconMenuEyeClose from '@/assets/images/icons/v-icon-menu-eye-close.vue'
export default {
  name: 'VAdminMenuBtn',
  components: {
    vIconMenuEye,
    vIconMenuEyeClose,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['handleClick'],
  methods: {
    handleClick() {
      this.$emit('handleClick')
    },
  },
}
</script>

<style lang="scss" scoped>
.vAdminMenuBtn {
  width: 32px;
  min-width: 32px;
  background: #f7f8fa;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.active {
    background: #f8f9fe;
  }

  &:hover {
    &.--eye {
      border: 1px solid #d5dbf1;
    }

    &.--edit {
      svg path {
        stroke: #6764ff !important;
      }
    }
  }
}
</style>
