<template>
  <section class="vSectionMenuAdmin section-profile">
    <!-- STEP 1 -->
    <vAdminMenuStepOne
      v-if="step == 1"
      @updateMenu="updateMenu"
    />

    <div
      v-if="step == 2"
      class="vSectionMenuAdmin__container"
    >
      <div class="vSectionMenuAdmin__aside">
        <vAdminMenuAside
          v-if="GET_CATEGORIES.length"
          :categories="GET_CATEGORIES"
          :select-catgory-i-d="selectCatgoryID"
          :state-hide-inacive-categories="stateHideInaciveCategories"
          @changeStateInaciveCategories="changeStateInaciveCategories"
          @updateMenu="updateMenu"
          @selectCatgory="selectCatgory"
        />
      </div>
      <div class="vSectionMenuAdmin__main-side">
        <vAdminMenuMainAside
          :light-preloader="lightPreloader"
          :select-catgory-data="selectCatgoryData"
          @toggleGridView="toggleGridView"
          @resetSelected="resetSelected"
          @selectCatgory="selectCatgory"
        />
      </div>
    </div>

    <div class="vSectionMenuAdmin__zaglushka">
      Сейчас ведутся работы над <br>
      мобильной реализацией меню, <br>
      скоро мы выпустим обновление
    </div>
    <!--    <div-->
    <!--      v-if="updatePreloader"-->
    <!--      class="updatePreloader"-->
    <!--    >-->
    <!--      <img-->
    <!--        src="~@/assets/images/preloader.gif"-->
    <!--        alt="Загрузка..."-->
    <!--        class="updatePreloader__img"-->
    <!--      >-->
    <!--      Обновляем меню-->
    <!--    </div>-->
    <vPreloaaderTimer
      v-if="preloader"
      :counter="counter"
    />
    <vLimitModal
      v-if="alert"
      @toggleLimit="toggleLimit"
    />
  </section>
</template>

<script>
import { computed } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import vAdminMenuAside from '@/components/adminMenu/v-admin-menu-aside'
import vAdminMenuMainAside from '@/components/adminMenu/v-admin-menu-main-side'
import vLimitModal from '@/components/order/v-limit-modal'
import vAdminMenuStepOne from '@/components/adminMenu/v-admin-menu-step-one'
import vPreloaaderTimer from '@/components/v-preloader-timer'

export default {
  name: 'MenuAdmin',
  components: {
    vAdminMenuAside,
    vAdminMenuMainAside,
    vLimitModal,
    vAdminMenuStepOne,
    vPreloaaderTimer,
  },
  provide() {
    return {
      selectCatgoryData: computed(() => this.selectCatgoryData),
      selectCatgoryName: computed(() => this.selectCatgoryName),
      gridView: computed(() => this.gridView),
    }
  },
  data() {
    return {
      step: 0,
      preloader: false,
      counter: 0,
      polling: null,
      selectCatgoryData: [],
      selectCatgoryName: {},
      gridView: false,
      alert: false,
      updatePreloader: false,
      lightPreloader: false,
      selectCatgoryID: null,
      reserveSelectCategory: {},
      stateHideInaciveCategories: false,
    }
  },
  computed: {
    ...mapGetters('user', ['USER_DATA', 'GET_AUTHENTIFICATED']),
    ...mapGetters('menuAdmin', ['GET_CATEGORIES']),
  },
  async mounted() {
    await this.FETCH_MENU({
      token: this.GET_AUTHENTIFICATED,
    }).then((res) => {
      this.step = 1
      if (res && res === 'error') {
        // this.errorModalActive()
        this.step = 1
      }
    })
    if (this.GET_CATEGORIES.length) {
      this.step = 2
    }
    this.TOGGLE_PRELOADER(false)
  },
  beforeUnmount() {
    clearInterval(this.polling)
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('menuAdmin', [
      'FETCH_MENU',
      'MENU_UPDATE',
      'CLEAR_CATEGORIES',
    ]),
    ...mapActions('alertModalText', ['SET_NEW_TEXT']),

    changeStateInaciveCategories(val) {
      this.stateHideInaciveCategories = val
    },
    resetSelected() {
      ;(this.selectCatgoryData = []),
        (this.selectCatgoryName = {}),
        (this.selectCatgoryID = null)
    },
    toggleLightPreloader() {
      this.lightPreloader = !this.lightPreloader
    },
    errorModalActive() {
      this.SET_NEW_TEXT(
        'Что-то пошло не так, попробуйте <br> выгрузить меню ещё раз'
      )
      this.alert = !this.alert
      setTimeout(() => {
        this.alert = !this.alert
      }, 4000)
    },
    toggleLimit() {
      this.alert = !this.alert
    },
    toggleGridView(val) {
      this.gridView = val
    },
    selectCatgory(payload) {
      console.log('payload', payload)
      if (payload.repeat) {
        let el = null
        const ID = this.reserveSelectCategory.id
        this.GET_CATEGORIES.forEach((i) => {
          if (i.id === ID) {
            el = i
          }
        })

        this.selectCatgoryID = this.reserveSelectCategory.id
        this.selectCatgoryData = el.products
        this.selectCatgoryName = el

        return
      }
      this.toggleLightPreloader()
      this.selectCatgoryID = payload.data.id
      this.selectCatgoryData = payload.data.products
      this.selectCatgoryName = payload.data
      this.reserveSelectCategory = payload.data
      setTimeout(() => {
        this.toggleLightPreloader()
      }, 500)
    },
    togglePreloaderTimer() {
      this.preloader = !this.preloader
    },
    async updateMenu() {
      console.log('updateMenu start')
      this.preloader = true
      this.CLEAR_CATEGORIES()
      this.resetSelected()
      this.counter = 0
      await this.MENU_UPDATE({
        token: this.GET_AUTHENTIFICATED,
        shop_id: this.USER_DATA.shops_list[0].id,
      }).then((res) => {
        console.log('menu update:', res)
        if (!res) {
          this.step = 1
          this.errorModalActive()
          this.preloader = false
          return
        }
        this.polling = setInterval(() => {
          this.FETCH_MENU({
            token: this.GET_AUTHENTIFICATED,
            cl: true,
          })
            .then(async (res) => {
              console.log('Interval', res)
              if (res) {
                console.log('interval res', res)
                this.counterIncrement(res)
                if (res >= 100) {
                  console.log('res >= 100')
                  clearInterval(this.polling)
                  this.step = 2
                  // await this.$refs.stepTwo.updateCategories()
                  // this.preloader = false;
                  this.preloader = false
                }
              }
            })
            .catch((e) => {
              this.step = 1
              this.preloader = false
            })
        }, 3000)
      })
    },
    counterIncrement(newCounter) {
      console.log('counterIncrement')
      this.counter = newCounter
    },
    // nexStep() {
    //   console.log('nexStep')
    //   this.step++
    //   this.togglePreloaderTimer()
    // }
  },
}
</script>

<style lang="scss" scoped>
.vSectionMenuAdmin {
  &__container {
    display: flex;
    background: #fff;
    border: 1px solid #e6e7eb;
    padding: 24px 28px;
    border-radius: 12px;
    height: calc(100vh - 150px);
    width: 100%;
    max-width: 1300px;
    min-width: 1100px;
    @media (max-width: 768px) {
      display: none;
    }
  }
  &__aside {
    width: 292px;
    margin-right: 16px;
    height: 100%;
  }
  &__main-side {
    width: calc(100% - 308px);
  }
  &__zaglushka {
    display: none;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #343d5d;
    font-size: 16px;
    line-height: 20px;
    background: #f8f9fd;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    @media (max-width: 768px) {
      display: flex;
    }
  }
}

.updatePreloader {
  background: #dcdee3;
  opacity: 0.9;
  backdrop-filter: blur(53px);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #010d35;
  font-weight: 600;
  &__img {
    width: 56px;
    height: 56px;
  }
}
</style>
