<template>
  <div class="vAdminMenuVisitors">
    <div class="vAdminMenuVisitors__text">
      Включить меню для посетителей
    </div>
    <label class="vAdminMenuVisitors__switch">
      <input
        type="checkbox"
        :checked="visibleMenu"
        @click="toggleVisibleMenu"
      >
      <span class="vAdminMenuVisitors__slider" />
    </label>
  </div>
</template>

<script>
export default {
  name: 'VAdminMenuVisitors',
  props: {
    visibleMenu: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  emits: ['toggleVisibleMenu'],
  methods: {
    toggleVisibleMenu() {
      this.$emit('toggleVisibleMenu')
    },
  },
}
</script>

<style lang="scss">
.vAdminMenuVisitors {
  width: 255px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  padding: 0 16px;
  margin-right: 12px;
  @media (max-width: 1180px) {
    width: 100%;
    margin-bottom: 12px;
  }
  &__text {
    color: #010d35;
    font-size: 14px;
    @media (max-width: 564px) {
      max-width: 155px;
    }
  }
  &__switch {
    position: relative;
    display: inline-block;
    min-width: 42px;
    height: 24px;
    @media (max-width: 564px) {
      max-width: 42px;
    }
    & input {
      display: none;
      &:checked + .vAdminMenuVisitors__slider {
        background-color: #6764ff;
      }
      &:disabled + .vAdminMenuVisitors__slider {
        background-color: #e6e7eb;
      }
      &:checked + .vAdminMenuVisitors__slider::before {
        transform: translateX(19px);
      }
    }
  }
  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e6e7eb;
    transition: 0.4s;
    border-radius: 50px;
    &::before {
      position: absolute;
      content: '';
      width: 19px;
      height: 19px;
      left: 2px;
      bottom: 3px;
      background-color: #fff;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}
</style>
