<template>
  <div class="unloading-menu">
    <vButton
      type="button"
      :fix-width="true"
      @click="togglePreloaderTimer"
    >
      Выгрузить меню
    </vButton>
    <div class="unloading-menu__text">
      Для начала работы вам нужно выгрузить меню
    </div>
  </div>
</template>

<script>
import vButton from '@/components/v-button'

export default {
  name: 'VAdminMenuStepOne',
  components: {
    vButton,
  },
  emits: ['updateMenu'],
  methods: {
    togglePreloaderTimer() {
      this.$emit('updateMenu')
    },
  },
}
</script>
<style lang="scss">
.unloading-menu {
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
