<template>
  <div class="vAdminMenuMainAside">
    <div class="vAdminMenuMainAside__row">
      <vAdminMenuVisitors
        :visible-menu="visibleMenu"
        @toggleVisibleMenu="toggleVisibleMenu"
      />
    </div>
    <div class="vAdminMenuMainAside__row">
      <vAdminMenuCategoryShield />
      <vAdminMenuViews
        @toggleGridView="toggleGridView"
        @resetEditing="resetEditing"
      />
    </div>
    <div class="vAdminMenuMainAside__body">
      <vAdmiMenuTable
        :select-catgory-data="selectCatgoryData"
        @togglePreloader="togglePreloader"
        @resetSelected="resetSelected"
        @selectCatgory="selectCatgory"
      />
      <vLightPreloader
        v-show="lightPreloader || preloader"
        :fixed="false"
        :transparent="true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vAdminMenuViews from '@/components/adminMenu/v-admin-menu-views'
import vAdminMenuVisitors from '@/components/adminMenu/v-admin-menu-visitors'
import vAdmiMenuTable from '@/components/adminMenu/v-admin-menu-table'
import vAdminMenuCategoryShield from '@/components/adminMenu/v-admin-menu-category-shield'
import vLightPreloader from '@/components/v-light-preloader'
export default {
  name: 'VAdminMenuMainAside',
  components: {
    vAdminMenuViews,
    vAdminMenuVisitors,
    vAdmiMenuTable,
    vAdminMenuCategoryShield,
    vLightPreloader,
  },
  props: {
    lightPreloader: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectCatgoryData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: ['toggleGridView', 'resetSelected', 'selectCatgory'],
  data() {
    return {
      visibleMenu: 1,
      tables: [],
      preloader: false,
    }
  },
  computed: {
    ...mapGetters('menuAdmin', ['GET_VISIBLE_MENU']),
    ...mapGetters('user', ['GET_AUTHENTIFICATED', 'USER_DATA']),
  },
  mounted() {
    this.visibleMenu = this.GET_VISIBLE_MENU
  },
  methods: {
    ...mapActions('menuAdmin', ['MENU_VISIBLE']),
    selectCatgory(payload) {
      this.$emit('selectCatgory', payload)
    },
    resetSelected() {
      this.$emit('resetSelected')
    },
    togglePreloader() {
      this.preloader = !this.preloader
    },
    toggleGridView(val) {
      this.$emit('toggleGridView', val)
    },
    resetEditing() {
      this.tables.forEach((i) => {
        i.editContent = false
      })
    },
    async toggleVisibleMenu() {
      this.preloader = true
      await this.MENU_VISIBLE({
        token: this.GET_AUTHENTIFICATED,
        domen: this.USER_DATA.shops_list[0].code,
      })
      setTimeout(() => {
        this.preloader = false
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.vAdminMenuMainAside {
  &__row {
    margin-bottom: 12px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &__body {
    position: relative;
  }
}
</style>
