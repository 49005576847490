<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.34766"
      y="0.564209"
      width="15.6528"
      height="2.60879"
      rx="1.3044"
      stroke="none"
    />
    <circle
      r="1.3044"
      transform="matrix(1 0 0 -1 1.3044 1.78081)"
      stroke="none"
    />
    <rect
      x="4.34766"
      y="6.7395"
      width="15.6528"
      height="2.60879"
      rx="1.3044"
      stroke="none"
    />
    <circle
      r="1.3044"
      transform="matrix(1 0 0 -1 1.3044 7.9561)"
      stroke="none"
    />
    <rect
      x="4.34766"
      y="12.9146"
      width="15.6528"
      height="2.60879"
      rx="1.3044"
      stroke="none"
    />
    <circle
      r="1.3044"
      transform="matrix(1 0 0 -1 1.3044 14.1312)"
      stroke="none"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconList',
}
</script>
